<template>
    <div :style="style"
         class="loader">
        <div class="loader__wrapper">
            <div class="loader__dots">
                <div class="loader__dot"></div>
                <div class="loader__dot"></div>
                <div class="loader__dot"></div>
                <div class="loader__dot"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    params: {
      type: Object,
      default: () => ({
        style: {
          width: '100%',
          height: '100%',
        },
      }),
    },
  },
  computed: {
    style() {
      const style = {};
      Object.keys(this.params.style)
        .forEach((key) => {
          style[key] = this.params.style[key];
        });
      return style;
    },
  },
};
</script>
